import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "About",
  "slug": "about",
  "cover": "./Boat photo 2.JPG"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` Jenna Harris is a vocalist, composer and lecturer from Dublin. Jenna graduated from Newpark/DCU’s B.A. in Jazz Performance in 2006 and since then has been a member of the faculty. During Jenna’s research in Trinity college Dublin on the MA Music and Media Technologies, she built a computer application designed to counteract vocal tension caused by performance anxiety in improvising vocalists. `}</p>
    <p>{`In Jenna’s solo voice generated performances she combines clean vocal improvisation with improvisation of the effects she uses to process her voice live. A style of improv has developed that intrinsically links the two, both feeding back and forth from each other. These performances often incorporate reactive visuals that she builds as a platform to exhibit the voice as the instrument it is.`}</p>
    <p>{`Jenna is the founding member of three-piece group Berri, with leading creative musicians Matthew Jacobson and Derek Whyte. The group play original compositions as well as interpretations of jazz standards and pop songs from the 60’s/70’s/80’s. Their EP 'Shadow Chroí'is available here:  `}<a parentName="p" {...{
        "href": "https://berri2.bandcamp.com/album/shadow-chro",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://berri2.bandcamp.com/album/shadow-chro`}</a>{` `}</p>
    <p>{`Jenna regularly collaborates with guitarist and electronic musician Daniel Jacobson (Zoid). She is a member of guitarist Tommy Halferty’s group Voices and the founding member of the group Claritas, with vocalists Fiadh Rua Gregg and Aleka Potinga. `}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      